import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, filter, map, Observable, of, switchMap, take, tap, throwError} from 'rxjs';
import {DragDropItem} from '@fuse/services/confirmation/drag-drop/drag-drop.component';
import {Product, Tag} from './products.types';
import {Category} from '../categories/categories.types';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private _categories: BehaviorSubject<Category[] | null> = new BehaviorSubject(null);
  private _product: BehaviorSubject<Product | null> = new BehaviorSubject(null);
  private _products: BehaviorSubject<Product[] | null> = new BehaviorSubject(null);
  private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}

  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  get product$(): Observable<Product> {
    return this._product.asObservable();
  }

  get products$(): Observable<Product[]> {
    return this._products.asObservable();
  }

  get tags$(): Observable<Tag[]> {
    return this._tags.asObservable();
  }

  getCategories(): Observable<Category[]> {
    return this._httpClient.get<Category[]>('api://category').pipe(
      tap(response => {
        this._categories.next(response);
      }),
    );
  }

  getProducts(sort: string = 'Name', order: 'asc' | 'desc' | '' = 'asc', search: string = ''): Observable<Product[]> {
    return this._httpClient
      .get<Product[]>('api://product', {
        params: {
          all: true,
          sort,
          order,
          search,
        },
      })
      .pipe(
        tap(response => {
          this._products.next(response);
        }),
      );
  }

  getProductById(id: string): Observable<Product> {
    if (id === 'new') return this.addProduct();

    return this._products.pipe(
      take(1),
      switchMap(products => {
        if (products?.length) return of(products);
        return this.getProducts();
      }),
      switchMap(products => {
        const product = products.find(item => item.Id === id) || null;
        if (!product) return throwError(() => new Error('Could not found product with id of ' + id + '!'));

        this._product.next(product);
        return of(product);
      }),
    );
  }

  addProduct(): Observable<Product> {
    const product: Product = {
      Id: null,
      CategoryId: null,
      StrId: '',
      Name: '',
      Description: '',
      Cost: null,
      PreviousCost: null,
      OrderBy: 9999,
      IsActive: true,
      AmountInStock: 0,
      CreatedAt: null,
      UpdatedAt: null,

      Category: null,
      ProductImages: [],
      ProductSizes: [],
      ProductTags: [],
      ProductReviews: [],
    };

    return this.products$.pipe(
      take(1),
      switchMap(async products => {
        this._product.next(product);
        this._products.next([product, ...products]);
        return product;
      }),
    );
  }

  createProduct(product: Product): Observable<Product> {
    return this.products$.pipe(
      take(1),
      switchMap(products =>
        this._httpClient.post<Product>(`api://product`, product).pipe(
          map(response => {
            this._products.next([...products, response]);
            return response;
          }),
        ),
      ),
    );
  }

  updateProduct(id: string, product: Product): Observable<Product> {
    return this.products$.pipe(
      take(1),
      switchMap(products =>
        this._httpClient.patch<Product>(`api://product/${id}`, product).pipe(
          map(response => {
            const index = products.findIndex(item => item.Id === id);
            products[index] = response;
            this._products.next(products);
            return response;
          }),
        ),
      ),
    );
  }

  deleteProduct(id: string): Observable<void> {
    return this.products$.pipe(
      take(1),
      switchMap(products =>
        this._httpClient.delete(`api://product/${id}`).pipe(
          map(() => {
            const index = products.findIndex(item => item.Id === id);
            products.splice(index, 1);
            this._products.next(products);
          }),
        ),
      ),
    );
  }

  updateProductOrder(items: DragDropItem[]): Observable<void> {
    return this.products$.pipe(
      take(1),
      switchMap(products =>
        this._httpClient.patch<void>(`api://product/order`, {Data: items}).pipe(
          map(() => {
            let reorderedProducts: Product[] = [];
            for (let item of items) {
              const product = products.find(product => product.Id === item.Id);
              if (product) reorderedProducts.push(product);
            }

            this._products.next(reorderedProducts);
          }),
        ),
      ),
    );
  }

  getTags(): Observable<Tag[]> {
    return this._httpClient.get<Tag[]>('api://tag').pipe(
      tap(response => {
        this._tags.next(response);
      }),
    );
  }

  createTag(name: string): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap(tags =>
        this._httpClient
          .post<Tag>('api://tag', {
            Name: name,
            Color: 'default',
          })
          .pipe(
            map(response => {
              this._tags.next([...tags, response]);
              return response;
            }),
          ),
      ),
    );
  }

  updateTag(id: string, name: string): Observable<Tag> {
    return this.tags$.pipe(
      take(1),
      switchMap(tags =>
        this._httpClient
          .put<Tag>(`api://tag/${id}`, {
            Name: name,
          })
          .pipe(
            map(response => {
              const index = tags.findIndex(item => item.Id === id);
              tags[index] = response;
              this._tags.next(tags);
              return response;
            }),
          ),
      ),
    );
  }

  deleteTag(id: string): Observable<boolean> {
    return this.tags$.pipe(
      take(1),
      switchMap(tags =>
        this._httpClient.delete(`api://tag/${id}`).pipe(
          map((isDeleted: boolean) => {
            const index = tags.findIndex(item => item.Id === id);
            tags.splice(index, 1);
            this._tags.next(tags);
            return isDeleted;
          }),
          filter(isDeleted => isDeleted),
          switchMap(isDeleted =>
            this.products$.pipe(
              take(1),
              map(products => {
                products.forEach(product => {
                  const tagIndex = product.ProductTags.findIndex(tag => tag.Id === id);
                  if (tagIndex > -1) {
                    product.ProductTags.splice(tagIndex, 1);
                  }
                });

                return isDeleted;
              }),
            ),
          ),
        ),
      ),
    );
  }
}
