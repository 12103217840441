import {Router} from '@angular/router';
import {Component, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'inv-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() title: string = '';
  @Input() isLoading: boolean = false;
  @Input() backRoute: string;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  constructor(private _router: Router) {}

  delete(): void {
    this.onDelete.emit();
  }

  save(): void {
    this.onSave.emit();
  }

  goToAll(): void {
    this._router.navigateByUrl(this.backRoute);
  }
}
