import {MatCheckboxModule} from '@angular/material/checkbox';
import {MultiselectComponent} from './multiselect/multiselect.component';
import {UploaderComponent} from './uploader/uploader.component';
import {HeaderComponent} from './header/header.component';
import {SearchFilterPipe} from './pipes/search-filter.pipe';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NoResultsComponent} from './no-results/no-results.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [NoResultsComponent, HeaderComponent, SearchFilterPipe, UploaderComponent, MultiselectComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatCheckboxModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, NoResultsComponent, HeaderComponent, SearchFilterPipe, UploaderComponent, MultiselectComponent],
})
export class SharedModule {}
