import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'inv-no-results',
  templateUrl: './no-results.component.html',
})
export class NoResultsComponent implements OnInit {
  @Input() name: string = '';
  @Input() namePlural: string = '';
  @Input() query: string = '';
  @Output() onNewItem: EventEmitter<any> = new EventEmitter();
  @Output() onClear: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  newItem(): void {
    this.onNewItem.emit();
  }

  clear(): void {
    this.onClear.emit();
  }
}
