import {UploaderService} from 'app/shared/uploader.service';
import {Component, ElementRef, Inject, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper';
import {ImageCropperConfig} from '../cropper.types';
import {ToastService} from 'app/shared/toast.service';
import {S3File} from 'app/types/file.types';

@Component({
  selector: 'cropper-dialog',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  styles: [
    /* language=SCSS */
    `
      .cropper-dialog-panel {
        width: 400px;
        max-width: 95vw !important;

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CropperDialogComponent implements AfterViewInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  loaded: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperConfig,
    public matDialogRef: MatDialogRef<CropperDialogComponent>,
    private _toast: ToastService,
    private _uploaderService: UploaderService
  ) {}

  ngAfterViewInit() {
    this.fileInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    if (event.target.files[0].size <= 10485760) {
      this.loaded = true;
      this.imageChangedEvent = event;
    } else {
      this.loaded = false;
      this._toast.error('Image size is too large. Please choose an image smaller than 10 MB');
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }

  save() {
    if (!this.croppedImage) return;

    const filename = this.fileInput.nativeElement.value.replace(/.*[\/\\]/, '');
    const file = new File([base64ToFile(this.croppedImage)], filename, {
      type: 'image/png',
      lastModified: new Date().getTime(),
    });

    if (file.type !== 'image/png') {
      this._toast.error('Something went wrong while converting image', 'File Conversion Error');
      return;
    }

    this._uploaderService.uploadImage(file).subscribe((response) => this.matDialogRef.close(response));
  }


}
