<div class="relative flex flex-col w-full h-full">

  <!-- Content -->
  <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

    <ng-container *ngIf="data.title">
      <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

        <ng-container *ngIf="data.title">
          <div class="text-xl leading-6 font-medium"
               [innerHTML]="data.title"></div>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <div class="flex flex-col">
    <table class="hidden md:block">
      <tr>
        <td>
          <button mat-button
                  (click)="rotateLeft()"
                  [disabled]="!showCropper"
                  class="px-2">
            <mat-icon class="icon-size-5 mr-2">rotate_90_degrees_ccw</mat-icon>
            Rotate Left
          </button>
        </td>
        <td>
          <button mat-button
                  (click)="rotateRight()"
                  [disabled]="!showCropper"
                  class="px-2">
            <mat-icon class="icon-size-5 mr-2">rotate_90_degrees_cw</mat-icon>
            Rotate Right
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button mat-button
                  (click)="flipHorizontal()"
                  [disabled]="!showCropper"
                  class="px-2">
            <mat-icon class="icon-size-5 mr-2">swap_horizontal_circle</mat-icon>
            Flip Horizontally
          </button>
        </td>
        <td>
          <button mat-button
                  (click)="flipVertical()"
                  [disabled]="!showCropper"
                  class="px-2">
            <mat-icon class="icon-size-5 mr-2">swap_vertical_circle</mat-icon>
            Flip Vertically
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button mat-button
                  (click)="resetImage()"
                  [disabled]="!showCropper"
                  tooltip="Reset"
                  class="px-2">
            <mat-icon class="icon-size-5 mr-2">clear_all</mat-icon>
            Reset Image
          </button>
        </td>
      </tr>
    </table>

    <div class="md:hidden">
      <button mat-button
              (click)="rotateLeft()"
              [disabled]="!showCropper"
              class="px-2">
        <mat-icon class="icon-size-5 mr-2">rotate_90_degrees_ccw</mat-icon>
      </button>
      <button mat-button
              (click)="rotateRight()"
              [disabled]="!showCropper"
              class="px-2">
        <mat-icon class="icon-size-5 mr-2">rotate_90_degrees_cw</mat-icon>
      </button>
      <button mat-button
              (click)="flipHorizontal()"
              [disabled]="!showCropper"
              class="px-2">
        <mat-icon class="icon-size-5 mr-2">swap_horizontal_circle</mat-icon>
      </button>
      <button mat-button
              (click)="flipVertical()"
              [disabled]="!showCropper"
              class="px-2">
        <mat-icon class="icon-size-5 mr-2">swap_vertical_circle</mat-icon>
      </button>
      <button mat-button
              (click)="resetImage()"
              [disabled]="!showCropper"
              tooltip="Reset"
              class="px-2">
        <mat-icon class="icon-size-5 mr-2">clear_all</mat-icon>
      </button>
    </div>
  </div>

  <input id="file-input"
         class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
         type="file"
         [multiple]="false"
         [accept]="'image/jpeg, image/png'"
         (change)="fileChangeEvent($event)"
         #fileInput>

  <div *ngIf="!showCropper"
       class="file-container text-center">
    <button mat-flat-button
            color="primary"
            (click)="fileInput.click()"
            class="btn-primary mt-8">
      <mat-icon class="icon-size-5 mr-3"
                [svgIcon]="'heroicons_solid:upload'"></mat-icon> Upload
    </button>
  </div>

  <div class="cropper-container">
    <image-cropper [imageChangedEvent]="imageChangedEvent"
                   [maintainAspectRatio]="true"
                   [containWithinAspectRatio]="true"
                   [aspectRatio]="1 / 1"
                   [cropperMinWidth]="128"
                   [onlyScaleDown]="true"
                   [roundCropper]="false"
                   [canvasRotation]="canvasRotation"
                   [transform]="transform"
                   [alignImage]="'left'"
                   [style.display]="showCropper ? null : 'none'"
                   format="png"
                   (imageCropped)="imageCropped($event)"
                   (imageLoaded)="imageLoaded()"
                   (cropperReady)="cropperReady($event)"
                   (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>

  <!-- Actions -->
  <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
    <div
         class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

      <ng-container *ngIf="data.actions.cancel.show">
        <button mat-stroked-button
                [matDialogClose]="null">
          Cancel
        </button>
      </ng-container>

      <ng-container *ngIf="data.actions.confirm.show">
        <button mat-flat-button
                color="primary"
                (click)="save()"
                [disabled]="
                !croppedImage">
          Save
        </button>
      </ng-container>

    </div>
  </ng-container>

</div>