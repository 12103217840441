import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseDragDropConfig } from '@fuse/services/confirmation/drag-drop.types';

export interface DragDropItem {
  Id: string;
  Name: string;
}

@Component({
  selector: 'fuse-drag-drop-dialog',
  templateUrl: './drag-drop.component.html',
  styles: [
    /* language=SCSS */
    `
      .fuse-drag-drop-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FuseDragDropDialogComponent {
  items: DragDropItem[];
  selectedItem: DragDropItem;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FuseDragDropConfig, public matDialogRef: MatDialogRef<FuseDragDropDialogComponent>) {
    this.items = data.items ?? [];
    if (this.items.length > 0) this.selectedItem = this.items[0];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }
}
