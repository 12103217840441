<ng-container>
  <ng-container *ngIf="query">
    <div class="p-8 sm:p-16 pb-2 sm:pb-4 border-t text-4xl font-semibold tracking-tight text-center">
      No search results found
    </div>
    <div class="text-center">
      <button mat-flat-button
              [color]="'primary'"
              (click)="clear()">
        <span class="ml-2 mr-1">Clear Search</span>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="!query">
    <div class="p-8 sm:p-16 pb-2 sm:pb-4 border-t text-4xl font-semibold tracking-tight text-center">
      There are no {{namePlural}}!
    </div>
    <div class="text-center">
      <button mat-flat-button
              [color]="'primary'"
              (click)="newItem()">
        <span class="ml-2 mr-1">Add {{name}}</span>
      </button>
    </div>
  </ng-container>
</ng-container>