import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of, switchMap, throwError} from 'rxjs';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  constructor(private _httpClient: HttpClient, private _userService: UserService, private _router: Router) {}

  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  signIn(credentials: {Email: string; Password: string; InviteCode?: string}): Observable<any> {
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this._httpClient.post('api://auth/login', credentials).pipe(
      switchMap((response: any) => {
        this.accessToken = response.accessToken.token;
        this._authenticated = true;
        // todo: exclude some props in user object that comes from server
        this._userService.user = response.user;
        return of(response);
      }),
    );
  }

  signInUsingToken(): Observable<any> {
    return this._httpClient.get('api://auth/refresh-token').pipe(
      catchError(() =>
        of(false),
      ),
      switchMap((response: any) => {
        if (!response.accessToken) {
          this._router.navigateByUrl('sign-in');
        }

        this.accessToken = response.accessToken.token;
        this._authenticated = true;
        this._userService.user = response.user;
        return of(true);
      }),
    );
  }

  signOut(): Observable<any> {
    localStorage.removeItem('accessToken');
    this._authenticated = false;
    return of(true);
  }

  signUp(user: {name: string; email: string; password: string; company: string}): Observable<any> {
    return this._httpClient.post('api://auth/signup', user).pipe(
      switchMap((response: any) => {
        this.accessToken = response.accessToken.token;
        this._authenticated = true;
        this._userService.user = response.user;
        return of(response);
      }),
    );
  }

  unlockSession(credentials: {email: string; password: string}): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
