import {ProductsService} from 'app/modules/products/products.service';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {debounceTime} from 'rxjs';

@Component({
  selector: 'inv-multiselect',
  templateUrl: './multiselect.component.html',
})
export class MultiselectComponent {

  // todo: check if item contains Name prop otherwise maybe check another like Title or throw error for dev
  // todo: fix the naming of these, its stupid
  @Input() titlePlural: string = '';
  @Input() titleSingular: string = '';
  @Input() items: any[] = [];
  @Input() prop: string = '';
  @Input() allowCreate: boolean = false;
  @Input() editMode: boolean = false;
  @Output() changes: EventEmitter<any> = new EventEmitter();

  filteredItems: any[];
  selectedIds: string[] = [];

  constructor(
    private _productsService: ProductsService,
  ) {}

  ngOnInit(): void {
    this.filteredItems = this.items;
  }

  emitChanges(): void {
    this.changes.emit(this.selectedIds);
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  filter(event): void {
    const value = event.target.value.toLowerCase();
    this.filteredItems = this.items.filter(item => item.Name.toLowerCase().includes(value));
  }

  filterInputKeyDown(event): void {
    // Return if the pressed key is not 'Enter'
    if (event.key !== 'Enter') {
      return;
    }

    // If there is no item available...
    if (this.filteredItems.length === 0) {
      this.create(event.target.value);
      event.target.value = '';
      return;
    }

    // If there is a item...
    const item = this.filteredItems[0];
    const isTagApplied = this.selectedIds.find(id => id === id);

    // If the found item is already applied to the product...
    if (isTagApplied) this.remove(item.Id);
    else this.add(item.Id);
  }

  create(title: string): void {
    // todo: change to image service
    // Create item on the server
    this._productsService.createTag(title).subscribe(response => {
      // Add the item to the product
      this.add(response.Id);
    });
  }

  updateName(itemId: string, event): void {
    this._productsService.updateTag(itemId, event.target.value).pipe(debounceTime(300)).subscribe();
  }

  delete(itemId: string): void {
    this._productsService.deleteTag(itemId).subscribe();
  }

  toggle(id: string, change: MatCheckboxChange): void {
    if (change.checked) {
      this.add(id);
    } else {
      this.remove(id);
    }
  }

  add(id: string): void {
    this.selectedIds.unshift(id);
    this.emitChanges();
  }

  remove(id: string): void {
    this.selectedIds.splice(this.selectedIds.findIndex(_id => _id === id), 1);
    this.emitChanges();
  }

  isSelected(id: string): boolean {
    if (!this.selectedIds) return null;
    return this.selectedIds.some(_id => _id === id);
  }

  shouldShowCreateButton(inputValue: string): boolean {
    return !!!(inputValue === '' || this.items.findIndex(item => item.Name.toLowerCase() === inputValue.toLowerCase()) > -1);
  }

  getFieldValue(item: any[]): string {
    const value = this.prop.split('+').map((fragment: string) => {
      return `${fragment.split('.').reduce((a, b) => a ? a[b] : '', item)}`
    });
    return value.join(' ').trim();
  }
}
