import {ToastService} from './../toast.service';
import {FuseConfirmationService} from '@fuse/services/confirmation/confirmation.service';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {S3File} from 'app/types/file.types';
import {moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'inv-uploader',
  templateUrl: './uploader.component.html',
})
export class UploaderComponent {

  @Input() images: S3File[];
  @Input() max: number = 10;
  @Output() changes: EventEmitter<any> = new EventEmitter();

  imageIndex: number = 0;

  constructor(
    private _fuseConfirmationService: FuseConfirmationService,
    private _toast: ToastService,
  ) {}

  emitChanges(): void {
    this.changes.emit(this.images);
  }

  openImageCropper(): void {
    if (this.max !== 1 && this.images.length === this.max) {
      this._toast.warning(`No more than ${this.max} images can be added`, 'Image Limit Exceeded');
      return;
    }

    const confirmation = this._fuseConfirmationService.imageCropper({
      title: 'Image Uploader',
    });

    confirmation.afterClosed().subscribe(file => {
      if (this.max === 1) {
        this.images = [file];
      } else {
        this.images.push(file);
        this.imageIndex = this.images.length - 1;
      }
      this.emitChanges();
    });
  }

  removeImage(): void {
    this.images.splice(this.imageIndex, 1);
    this.imageIndex = 0;
    this.emitChanges();
  }

  moveImageLeft(): void {
    moveItemInArray(this.images, this.imageIndex, this.imageIndex - 1);
    this.imageIndex--;
    this.emitChanges();
  }

  moveImageRight(): void {
    moveItemInArray(this.images, this.imageIndex, this.imageIndex + 1);
    this.imageIndex++;
    this.emitChanges();
  }

  cycleImages(forward: boolean = true): void {
    const count = this.images.length;
    const currentIndex = this.imageIndex;

    if (forward) this.imageIndex = currentIndex + 1 === count ? 0 : currentIndex + 1;
    else this.imageIndex = currentIndex - 1 < 0 ? count - 1 : currentIndex - 1;
  }
}
