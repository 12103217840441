import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {S3File} from 'app/types/file.types';
import {BehaviorSubject, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  private _image: BehaviorSubject<S3File | null> = new BehaviorSubject(null);

  get image$(): Observable<S3File> {
    return this._image.asObservable();
  }

  constructor(private _httpClient: HttpClient) {}

  uploadImage(imageFile: File): Observable<S3File> {
    const imageForm = new FormData();
    imageForm.append('image', imageFile);

    return this._httpClient.post<S3File>(`api://image`, imageForm).pipe(
      tap(response => {
        this._image.next(response);
      }),
    );
  }
}
