<div class="relative flex flex-col w-full h-full">

  <!-- Content -->
  <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

    <ng-container *ngIf="data.title">
      <div class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

        <ng-container *ngIf="data.title">
          <div class="text-xl leading-6 font-medium"
               [innerHTML]="data.title"></div>
        </ng-container>
      </div>
    </ng-container>

  </div>

  <div class="mt-1.5 rounded-md border border-gray-300 dark:border-gray-500 shadow-sm overflow-hidden">
    <div cdkDropListGroup>
      <div class="dd-container">
        <div id="selected-item"
             cdkDropList
             [cdkDropListData]="selectedItem"
             class="dd-list"
             (cdkDropListDropped)="drop($event)">
          <div class="dd-box"
               *ngFor="let item of items"
               cdkDrag>
            <mat-icon class="icon-size-6 mr-4"
                      [svgIcon]="'heroicons_solid:switch-vertical'"></mat-icon>
            <span class="pr-2 align-left">{{item.Name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Actions -->
  <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
    <div
         class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

      <ng-container *ngIf="data.actions.cancel.show">
        <button mat-stroked-button
                [matDialogClose]="null">
          Cancel
        </button>
      </ng-container>

      <ng-container *ngIf="data.actions.confirm.show">
        <button mat-flat-button
                color="primary"
                [matDialogClose]="items">
          Update
        </button>
      </ng-container>

    </div>
  </ng-container>

</div>