import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  defaultOptions = {
    closeButton: true,
    extendedTimeOut: 3000,
    enableHtml: true,
    progressBar: true,
    positionClass: 'toast-bottom-right',
  }

  constructor(private _toastr: ToastrService) { }

  success(text: string, title: string = '', options = {}) {
    this._toastr.success(text, title, {...this.defaultOptions, ...options});
  }
  
  info(text: string, title: string = '', options = {}) {
    this._toastr.info(text, title, {...this.defaultOptions, ...options});
  }
  
  warning(text: string, title: string = '', options = {}) {
    this._toastr.warning(text, title, {...this.defaultOptions, ...options});
  }

  error(text: string, title: string = '', options = {}) {
    this._toastr.error(text, title, {...this.defaultOptions, ...{disableTimeout: true}, ...options});
  }

  xerror() {
    this._toastr.error('An unxpected problem has occured! Please try again or contact support', '', this.defaultOptions);
  }
}
