<span class="mb-px font-medium leading-tight">{{titlePlural}}</span>
<div class="mt-1.5 rounded-md border border-gray-300 dark:border-gray-500 shadow-sm overflow-hidden">
  <div class="flex items-center -my-px py-2 px-3">
    <div class="flex items-center flex-auto min-w-0">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:search'"></mat-icon>
      <input class="min-w-0 ml-2 py-1 border-0"
             type="text"
             placeholder="Enter {{titleSingular}} name"
             (input)="filter($event)"
             (keydown)="filterInputKeyDown($event)"
             [maxLength]="50"
             #newInput>
    </div>
    <button *ngIf="allowCreate"
            class="ml-3 w-8 h-8 min-h-8"
            mat-icon-button
            (click)="toggleEditMode()">
      <mat-icon *ngIf="!editMode"
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
      <mat-icon *ngIf="editMode"
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:check'"></mat-icon>
    </button>
  </div>

  <div class="h-44 leading-none overflow-y-auto border-t border-gray-300 dark:border-gray-500">
    <ng-container *ngIf="!editMode">
      <ng-container *ngFor="let item of filteredItems; trackBy: trackByFn">
        <mat-checkbox class="flex items-center h-10 min-h-10 px-4"
                      [color]="'primary'"
                      [checked]="isSelected(item)"
                      (change)="toggle(item.Id, $event)">
          {{getFieldValue(item)}}
        </mat-checkbox>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="editMode">
      <div class="p-4 space-y-2">
        <ng-container *ngFor="let item of filteredItems; trackBy: trackByFn">
          <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full">
            <input matInput
                   [value]="getFieldValue(item)"
                   (input)="updateName(item.Id, $event)">
            <button mat-icon-button
                    (click)="delete(item.Id)"
                    matSuffix>
              <mat-icon class="icon-size-5"
                        [svgIcon]="'heroicons_solid:trash'"></mat-icon>
            </button>
          </mat-form-field>
        </ng-container>
      </div>
    </ng-container>

    <div *ngIf="allowCreate && shouldShowCreateButton(newInput.value)"
         class="flex items-center h-10 min-h-10 -ml-0.5 pl-4 pr-3 leading-none cursor-pointer border-t hover:bg-gray-50 dark:hover:bg-hover"
         (click)="create(newInput.value); newInput.value = ''"
         matRipple>
      <mat-icon class="mr-2 icon-size-5"
                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
      <div class="break-all">Create "<b>{{newInput.value}}</b>"</div>
    </div>
  </div>
</div>