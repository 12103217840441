<div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-5 md:px-8 border-b">
  <!-- Loader -->
  <div class="absolute inset-x-0 bottom-0"
       *ngIf="isLoading">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <!-- Title -->
  <div class="text-2xl font-extrabold tracking-tight">{{title}}</div>
  <!-- Actions -->
  <div class="flex flex-shrink-0 items-center my-6">
    <div class="flex">
      <button class="bg-red-200"
              mat-flat-button
              (click)="delete()">
        <mat-icon class="icon-size-5"
                  [svgIcon]="'heroicons_solid:trash'"
                  class="mr-2 hidden md:block"></mat-icon>
        Delete
      </button>
      <button *ngIf="backRoute"
              class="ml-4 bg-gray-200"
              mat-flat-button
              (click)="goToAll()">
        <mat-icon class="icon-size-5"
                  [svgIcon]="'heroicons_solid:arrow-circle-left'"
                  class="mr-2 hidden md:block"></mat-icon>
        Back <span class="hidden md:block ml-1">to All</span>
      </button>
      <button class="ml-4"
              mat-flat-button
              [color]="'primary'"
              (click)="save()">
        <mat-icon class="icon-size-5"
                  [svgIcon]="'heroicons_solid:save'"
                  class="mr-2 hidden md:block"></mat-icon>
        Save
      </button>
    </div>
  </div>