import { Pipe, PipeTransform } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import _ from 'lodash';

@Pipe({
  name: 'searchFilter',
  pure: false,
})
export class SearchFilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param search search string
   * @param sort sort details
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], search: string, sort: MatSort): any[] {
    if (!items) return [];
    search = search.toLocaleLowerCase();

    if (search) {
      items = items.filter(item => {
        for (let [key] of sort.sortables) {
          if (_.get(item, key)?.toString().toLocaleLowerCase().includes(search)) return true;
        }

        return false;
      });
    }

    if (sort) {
      items = items.sort((a, b) => {
        return (_.get(a, sort.active) < _.get(b, sort.active) ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }

    return items;
  }
}
