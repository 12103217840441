import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FuseConfirmationService } from '@fuse/services/confirmation/confirmation.service';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { FuseDragDropDialogComponent } from '@fuse/services/confirmation/drag-drop/drag-drop.component';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperDialogComponent } from '@fuse/services/confirmation/cropper/cropper.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FuseConfirmationDialogComponent, FuseDragDropDialogComponent, CropperDialogComponent],
  imports: [MatButtonModule, DragDropModule, ImageCropperModule, MatDialogModule, MatIconModule, CommonModule, FormsModule],
  providers: [FuseConfirmationService],
})
export class FuseConfirmationModule {}
