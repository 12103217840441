import { Company } from 'app/core/company/company.types';

export enum Role {
  User = 'user',
  Admin = 'admin',
  SuperAdmin = 'superadmin',
}

export interface UserToken {
  id: string;
  name: string;
  firstname: string;
  email: string;
  role: Role;
  avatar?: string;
  status?: string;
  company: Company;
}

export interface User {
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Avatar: string;
  Role: Role;
  ResetPassword: boolean;
  Company: Company;
}
