<div class="flex flex-col items-center">
  <div class="w-40">
    <button mat-icon-button
            (click)="moveImageLeft()"
            [disabled]="max === 1 || images.length === 0 || imageIndex === 0">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:chevron-double-left'"></mat-icon>
    </button>
    <button mat-icon-button
            (click)="openImageCropper()">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
    </button>
    <button mat-icon-button
            (click)="removeImage()"
            [disabled]="images.length === 0">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:trash'"></mat-icon>
    </button>
    <button mat-icon-button
            (click)="moveImageRight()"
            [disabled]="max === 1 || images.length === 0 || imageIndex === images.length - 1">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:chevron-double-right'"></mat-icon>
    </button>
  </div>
  <div class="flex justify-center items-center w-40 h-44 max-h-44 border rounded overflow-hidden">
    <ng-container *ngIf="images.length > 0; else noImage">
      <img class="w-full h-full object-contain py-3"
           [src]="images[imageIndex]?.Path200">
    </ng-container>
    <ng-template #noImage>
      <div class="min-h-20 text-lg font-semibold text-center">
          <mat-icon
              class="icon-size-10 text-hint"
              [svgIcon]="'heroicons_solid:photograph'"></mat-icon>
          <div>NO IMAGE</div>
      </div>
    </ng-template>
  </div>
  <div *ngIf="max > 1 && images.length > 0"
       class="flex items-center mt-2 whitespace-nowrap">
    <button mat-icon-button
            (click)="cycleImages(false)">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:arrow-circle-left'"></mat-icon>
    </button>
    <span class="font-sm">
      {{imageIndex + 1}} of {{images.length}}
    </span>
    <button mat-icon-button
            (click)="cycleImages(true)">
      <mat-icon class="icon-size-5"
                [svgIcon]="'heroicons_solid:arrow-circle-right'"></mat-icon>
    </button>
  </div>
</div>
